.Home {
    max-width: 100vw;
    font-family: "Abel", sans-serif !important;
    overflow-y: hidden;
}

.ant-layout-content {
    overflow-y: hidden;
}

::selection {
    background-color: #871212;
    color: white;
}

.section-we {
    text-align: center;
}

.section-we h1 {
    text-transform: uppercase;
    font-size: 2rem;
}

.section-we h3 {
    letter-spacing: 3px;
}

.section-we hr {
    margin: 2%;
}

.background-video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    pointer-events: none;
    z-index: -1;
}

.media {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    width: 100vw;
    z-index: 1;
    animation: fadeIn 2s ease-in-out;
}

.media h1 {
    font-size: 48px;
    font-weight: 300;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.ant-layout {
    background-color: rgb(0, 0, 0);
}

.ant-layout-content {
    top: 0%;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
}

.ant-layout-footer {
    top: 0%;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    padding: 0;
}

.section-we {
    justify-content: center;
    margin: 3% 0%;
}

.Photography,
.Videography,
.EventProduction,
.US,
.section-contact {
    margin: 7% 0%;
}

.Photography {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
}

.Photography-img {
    width: 100%;
    height: 100%;
    opacity: 35%;
    object-fit: cover;
    aspect-ratio: 5/2;
    transition: opacity 1s ease-in-out;
}

.Photography-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    height: 100%;
}

.Photography-contents,
.Videography-contents {
    position: absolute;
    padding: 3% 5%;
    top: 0%;
    left: 0%;
    z-index: 10;
    display: grid;
    width: 100%;
    grid-template-columns: 70%;
}

.Photography-header h1 {
    font-size: 2rem;
    letter-spacing: 7px;
}

.Photography-header p {
    font-size: 1rem;
}

.Photography-text {
    margin-top: 20%;
}

.Photography-text p {
    font-size: 16px;
}

.Photography-right {
    padding: 2%;
}

.Photography-text ul {
    padding: 0;
}

.ant-collapse {
    border: none;
    width: 60%;
}

.ant-collapse::before {
    overflow: hidden;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    color: #fff;
    border-color: transparent;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    width: 100%;
    height: 100%;
}

.ant-collapse .ant-collapse-content>.ant-collapse-content-box {
    background-color: #000;
    color: #fff;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

.more-section {
    text-align: start;
}

.more-section-photo {
    text-align: start;
    padding-top: 2%;
}

.more-section a,
.more-section-photo a {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 200;
    letter-spacing: 5px;
    color: #fff;
    cursor: pointer;
}

.more-section a:hover,
.more-section-photo a:hover {
    color: #871212;
    text-decoration: none;
}

.Photography-right {
    justify-self: space-between;
}

.list-table {
    display: table;
    width: 100%;
    border-collapse: collapse;
}

.list-row {
    display: table-row;
    line-height: 2rem;
}

.list-header,
.list-row p {
    display: table-cell;
    vertical-align: top;
    padding-right: 2rem;
}

.list-header {
    white-space: nowrap;
    display: flex;
}

.list-header h2 {
    margin: 0;
    vertical-align: top;
    padding-right: 1%;
}

.list-row p {
    width: 100vw;
    margin: 0;
}

.gallery-header {
    color: #fff;
    text-align: center;
}

.gallery-header p {
    letter-spacing: 3px;
}

.masonry-grid {
    column-count: 4;
    column-gap: 0.5rem;
    padding: 0;
}

.masonry-grid .ant-image {
    width: 100%;
}

.masonry-item {
    break-inside: avoid;
    margin-bottom: .5rem;

}

.gallery-image {
    width: 100%;
    box-shadow: 0;
    transition: transform 0.2s ease;
}

.gallery-image:hover {
    transform: scale(1);
}

.Videography {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-bottom: 1%;
}

.photo-container {
    background-color: #000;
    color: #fff;
    padding: 3%;
}

.Videography-video {
    width: 100%;
    height: 100%;
    opacity: 50%;
    object-fit: cover;
    aspect-ratio: 5/2;
}

.Videography-video iframe {
    width: 100%;
    height: 100%;
    border: none;
    aspect-ratio: 16/9;
    overflow: hidden;
}

.Videography-left {
    display: flex;
    flex-direction: column;
}

.Videography-header h1 {
    font-size: 2rem;
    letter-spacing: 7px;
}

.Videography-text p {
    margin-top: 40%;
    font-size: 16px;
    text-align: justify;
}

.play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff68;
    font-size: 50px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.video-description {
    position: absolute;
    color: white;
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
}

.video-thumbnail {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.video-container {
    margin: 0;
    padding-top: 2%;
}

.video-container a {
    padding-left: 2%;
}

.playlist-header h1,
.playlist-header p {
    text-align: center;
    margin-bottom: 40px;
    font-size: 2.5rem;
    color: white;
}

.playlist-header p {
    font-size: 1rem;
}

.video-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    gap: 1rem;
    justify-items: center;
    align-items: center;
    padding: 2% 4%;
}

.video-item {
    position: relative;
    width: auto;
    aspect-ratio: 16 / 9;
    cursor: pointer;
    overflow: hidden;
    height: 100%;
}

.video-item:hover {
    .video-description {
        visibility: visible;
        transition: visibility 0.1s ease-in-out;
    }

    border: 1px solid #fff;
}

.video-thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2px;
    flex-wrap: wrap;
}

.video-description {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 3rem;
    pointer-events: none;
    opacity: 0.8;
    visibility: hidden;
}


.EventProduction {
    margin: 5%;
}

.EventProduction-header {
    font-size: 1rem;
    letter-spacing: 7px;
}

.ant-modal-content {
    overflow-y: hidden;
}

.modal h2 {
    color: #fff;
}

.modal-video {
    width: 100vw;
}

.US {
    position: relative;
    width: 100%;
    height: 800px;
    overflow: hidden;
}

.US-image {
    width: 100%;
    height: 100%;
    opacity: 50%;
    object-fit: cover;
    aspect-ratio: 5/2;
}

.US-contents {
    position: absolute;
    padding: 5%;
    top: 0%;
    left: 0%;
    color: white;
    z-index: 10;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    text-align: justify;
}

.US-header h2 {
    font-size: 36px;
    font-weight: 400;
}

.US-text {
    margin-top: 5%;
    font-size: 20px
}

.US-text-sub {
    margin: 15% 0;
}

.US-text-sub h2 {
    font-size: 32px;
}

.US-text-sub p {
    font-size: 20px
}

.Community {
    overflow: hidden;
    z-index: 10;
    justify-items: center;
}

.Community h1 {
    font-size: 28px;
    text-align: left;
}

.Community-contents {
    border: 1px solid #fff;
    margin: 5%;
    padding: 5%;
    width: 80%;
    text-align: justify;
}

.Community-contents p {
    overflow: hidden;
    font-size: 18px;
}

.more-link-commu {
    color: #fff;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.more-icon-commu {
    transition: transform 0.3s ease;
    font-size: larger;
    vertical-align: bottom;
}

.more-link-commu:hover {
    color: #fff;
}

.more-link-commu:hover .more-icon-commu {
    transform: rotate(45deg);
}

.Community-text {
    text-align: justify;
    position: relative;
    padding: 10%;
    bottom: 0%;
    left: 0%;
    color: white;
    z-index: 10;
}

.Community-text h2 {
    font-size: 30px;
    font-weight: 300;
}

.Community-text p {
    font-size: 20px;
    font-weight: 200;
}

.section-contact {
    position: relative;
    display: grid;
    grid-template-columns: 50% 50%;
    overflow-y: hidden;
    justify-items: center;
    align-items: center;
}

.section-contact-pic {
    position: absolute;
    width: 100%;
    height: auto;
    object-fit: cover;
    filter: blur(2px);
    opacity: 0.5;
    aspect-ratio: 5/2;
}

.contact-form {
    position: relative;
    z-index: 1;
    backdrop-filter: blur(1px);
    border-radius: 10px;
    color: white;
    text-align: center;
    width: 100%;
    padding: 5%;
}

.contact-form h1 {
    text-align: center;
}

.form-group {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: right;
    margin-bottom: 20px;
}

.captcha div {
    overflow-y: hidden;
    width: 100%;
}

.form-group input {
    padding: 20px;
    background: #000;
    border: 1px solid #fff;
    border-radius: 4px;
    color: white;
    font-size: 18px;
}

.form-group textarea {
    width: 100%;
    padding: 20px;
    background: #000;
    border: 1px solid #fff;
    border-radius: 4px;
    color: white;
    margin-bottom: 20px;
    font-size: 18px;

}

.form-group input:focus,
.form-group textarea:focus {
    outline: none;
    border-color: #fff;
    font-size: 20px;

}

.form-group input::placeholder,
.form-group textarea::placeholder {
    font-family: "Abel", sans-serif !important;
    font-size: 18px;
    color: #fff;
    letter-spacing: 2px;
}

.send-button {
    background-color: transparent;
    color: #fff;
    padding: 10px 20px;
    border: 2px solid transparent;
    font-size: 18px;
    letter-spacing: 2px;
    cursor: pointer;
    transition: background 0.3s ease;
    border-radius: 4px;
}

.send-button:hover {
    color: #871212b9;
}

.contact-text-first img {
    width: 10%;
}

.contact-text-first h1 {
    font-size: 28px;
    font-weight: 450;
    text-align: center;
    letter-spacing: 8px;
}

.contact-text-first p {
    font-size: 18px;
    font-weight: 200;
}

.contact-text-first h2 {
    font-size: 24px;
}

.contact-text-first {
    text-align: center;
}

.ant-modal .ant-modal-content {
    overflow-y: hidden;
    background-color: #000;
    padding: 3%;
}

.ant-modal .ant-modal-close {
    color: #fff;
    font-size: 10rem;
}

.ant-modal .ant-modal-close:hover {
    color: #871212;
}

/* phone */
@media (max-width: 600px) {
    .Home {
        margin: 0;
        padding-top: 20%;
    }

    .background-video {
        display: none;
        object-fit: none;
        background-color: black;
    }

    .media h1 {
        font-size: 26px;
        font-weight: 400;
    }

    .section-we {
        display: block;
        height: max-content;
    }

    .contact-text-first b {
        font-size: 18px;
    }

    .Photography {
        margin: 20% 0%;
    }

    .EventProduction,
    .US,
    .Community,
    .section-contact {
        margin: 10% 0%;
    }

    .Photography-contents {
        position: absolute;
        padding: 3%;
        display: block;
    }

    .Photography-header {
        text-align: justify;
    }

    .Photography-header p {
        font-size: 12px;
        padding-top: 5%;
    }

    .Photography-header h1 {
        font-size: 16px;
    }

    .Photography-text {
        margin-top: 0;
        font-size: 14px;
    }

    .Photography-text ul {
        display: none;
    }

    .Photography-text {
        margin-top: 0;
    }

    .Photography-img {
        aspect-ratio: 16/9;
    }

    .Videography-contents {
        display: block;
        padding: 3%;
    }

    .Videography-video {
        aspect-ratio: 16/9;
    }

    .Videography-header h1 {
        text-align: justify;
        font-size: 18px;
    }

    .Videography-text p {
        margin-top: 5%;
        font-size: 11px;
    }

    .more-section a {
        margin-top: 0;
        font-size: 12px;
    }

    .Videography-bye {
        font-size: smaller;
    }

    .playlist-container {
        display: block;
    }

    .video-grid {
        grid-template-columns: 1fr;
    }

    .video-description {
        font-size: 0.9rem;
    }

    .EventProduction-header {
        font-size: 11px;
        padding: 0 3%;
    }

    .EventProduction-header h1 {
        font-size: 16px;
    }

    .US {
        height: fit-content;
    }

    .US-image {
        aspect-ratio: 1/2;
        height: 450px;
    }

    .US-contents {
        display: block;
    }

    .US-header h2,
    .US-text-sub h2 {
        font-size: 20px;
    }

    .US-text,
    .US-text-sub p {
        font-size: 14px;
    }

    .contact-text-first img {
        width: 20%;
    }

    .section-contact {
        display: block;
        align-items: center;
        justify-content: center;
        justify-items: center
    }

    .section-contact-pic {
        display: none;
    }

    .form-group textarea {
        height: 100%;
    }

    .contact-form {
        width: 100%;
    }

    .captcha {
        transform: scale(0.85);
        transform-origin: 0 10;
    }

    .contact-form h1 {
        text-align: center;
    }

    .form-group {
        justify-self: center;
        width: 100%;
    }

    .form-group input {
        padding: 10px;
        background: #000;
        border: 1px solid #fff;
        border-radius: 4px;
        color: white;
        z-index: -1;
    }

    .form-group textarea {
        padding: 10px;
        background: #000;
        border: 1px solid #fff;
        border-radius: 4px;
        color: white;
        margin-bottom: 20px;
        z-index: -1;
    }

    .form-group input::placeholder,
    .form-group textarea::placeholder {
        font-size: 16px;
    }

    .send-button {
        background-color: transparent;
        color: #fff;
        padding: 10px 20px;
        border: 2px solid transparent;
        font-size: 18px;
        letter-spacing: 2px;
        cursor: pointer;
        transition: background 0.3s ease;
        border-radius: 4px;
        align-self: center;
    }

    .send-button:hover {
        color: #871212b9;
    }

    .gallery-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .list-table {
        display: none;
    }

    .masonry-grid {
        column-count: 2;
    }

    .Community h1 {
        font-size: 18px;
    }

    .Community-contents {
        width: 90%;
    }

    .Community-contents h2 {
        font-size: 12px;
        font-weight: 500;
    }

    .Community-contents p,
    .Community-contents a {
        font-size: 12px;
    }

    .privacy{
        align-content: center;
    }

}

/* tablet */
@media (max-width: 820px) {
    .Home {
        margin-top: 5%
    }

    .background-video {
        display: none;
        object-fit: none;
        background-color: black;
    }

    .section-we {
        display: block;
        height: max-content;
    }

    .section-contact {
        flex-direction: column;
        margin: 2% 0;
    }

    .contact-pic {
        display: none;
    }

    .Videography-text p {
        margin-top: 5%;
        font-size: 11px;
    }

    .Photography-contents,
    .Videography-contents {
        position: absolute;
        padding: 3% 5%;
        top: 0%;
        left: 0%;
        z-index: 10;
        display: block;
        width: 100%;
    }

    .Photography-text {
        margin-top: 0;
    }

    .Photography-img,
    .Videography-img {
        aspect-ratio: 16/9;
    }

    .gallery-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .playlist-container {
        display: block;
    }

    .contact-form {
        width: 90%;
    }

    .section-contact-pic {
        display: none;
    }

    .list-table {
        display: none;
    }

    .Community-contents {
        width: 80%;
    }


    .US-contents {
        display: block;
    }

    .US-image {
        aspect-ratio: 16/9;
    }

}

/* large */
@media (min-width: 1200px) {}