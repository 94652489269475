.scroll-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  background: #87121261;
  color: white;
  border: none;
  padding: 12px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
  z-index: 20;
}

.scroll-to-top.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.scroll-to-top:hover {
  background: #871212d3;
  transform: scale(1.1);
}